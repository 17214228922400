import React from "react";
import { Button, ButtonColour, ButtonSize } from "./Button";

interface Props {
    logoElement: JSX.Element;
    title?: string,
    subtitle?: string,
    icon?: JSX.Element,
    ctaLabel?: string,
    ctaLink?: string,
    ctaAriaLabel?: string,
    className?: string,
    externalLink?: boolean;
    cta2Label?: string,
    cta2Link?: string,
    cta2AriaLabel?: string,
    externalLink2?: boolean;
}

export const HeaderInfo: React.FC<Props> = ({
    logoElement,
    title,
    subtitle,
    icon,
    ctaLabel,
    ctaLink,
    ctaAriaLabel,
    className = "",
    children,
    externalLink,
    cta2Label,
    cta2Link,
    cta2AriaLabel,
    externalLink2
}) => {
    return (
        <div className={`header-info-container ${className}`}>
            {icon &&
                icon
            }
            <div className="header-info">
                {logoElement && <>{logoElement}</>}
                {title && <h1 className="title">{title}</h1>}
                {subtitle && <h2 className="subtitle">{subtitle}</h2>}
                {((ctaLink && ctaLabel) || (cta2Link && cta2Label)) &&
                    <div className="cta-links">
                        {ctaLink && ctaLabel &&
                            <Button
                                buttonConfig={
                                    {
                                        id: "blueGradient",
                                        ctaLabel,
                                        ctaLink,
                                        size: ButtonSize.LARGE,
                                        colour: ButtonColour.WHITE_DARK,
                                        ariaLabel: ctaAriaLabel,
                                        externalLink: externalLink,
                                    }
                                }
                            />
                        }
                        {cta2Link && cta2Label &&
                            <Button
                                buttonConfig={
                                    {
                                        id: "blueGradient",
                                        ctaLabel: cta2Label,
                                        ctaLink: cta2Link,
                                        size: ButtonSize.LARGE,
                                        colour: ButtonColour.WHITE_DARK,
                                        ariaLabel: cta2AriaLabel,
                                        externalLink: externalLink2,
                                    }
                                }
                            />
                        }
                    </div>
                }
            </div>
            {children}
        </div>
    )
}